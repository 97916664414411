import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 446.000000 463.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,463.000000) scale(0.100000,-0.100000)">


<path d="M3584 4159 c-3 -5 -75 -9 -161 -9 -149 0 -159 -1 -210 -27 -54 -26
-81 -57 -111 -127 -17 -38 -17 -533 -1 -621 8 -39 22 -73 40 -97 l30 -37 -23
-5 c-13 -2 -117 -16 -233 -31 -115 -15 -213 -29 -217 -31 -5 -3 -8 -23 -8 -45
0 -21 -4 -39 -10 -39 -5 0 -10 -9 -10 -21 0 -19 5 -20 46 -17 35 2 49 -1 57
-14 8 -12 7 -131 -2 -408 -7 -216 -11 -394 -8 -396 2 -2 29 1 62 6 32 5 62 7
66 4 16 -9 10 -81 -7 -95 -20 -17 -165 -40 -178 -29 -7 7 8 304 34 654 11 141
0 182 -48 192 -27 5 -30 11 -35 56 -4 28 -3 56 1 62 3 6 8 28 9 50 3 35 1 38
-20 33 -12 -3 -67 -13 -122 -22 -55 -9 -116 -20 -135 -25 -32 -9 -75 -18 -92
-19 -5 -1 -8 -17 -8 -36 0 -22 -5 -35 -13 -35 -9 0 -12 11 -9 36 4 32 2 35
-14 31 -11 -3 -89 -20 -174 -38 -155 -31 -190 -46 -190 -81 0 -10 -4 -18 -10
-18 -5 0 -10 9 -10 19 0 11 -4 22 -9 25 -5 3 -88 -13 -185 -36 -165 -39 -176
-44 -176 -65 0 -13 -4 -23 -10 -23 -5 0 -10 -9 -10 -20 0 -11 -5 -20 -11 -20
-8 0 -10 12 -6 35 7 44 6 44 -127 6 -55 -16 -131 -37 -168 -47 -46 -13 -68
-24 -71 -36 -3 -10 -10 -18 -16 -18 -7 0 -11 26 -11 69 l0 68 98 7 c299 22
560 125 617 243 22 45 30 47 66 21 29 -22 40 -23 232 -25 119 -2 215 -7 236
-14 45 -15 97 -13 141 5 19 7 54 19 77 26 54 16 105 59 134 112 24 43 24 46
24 353 l0 310 -27 50 c-33 63 -70 98 -130 124 -43 18 -68 20 -338 19 -160 -1
-293 -5 -296 -9 -2 -4 -10 -6 -17 -4 -7 3 -36 -12 -66 -33 -51 -34 -84 -77
-99 -127 -4 -11 -5 -154 -4 -318 3 -271 2 -299 -14 -314 -32 -32 -84 -58 -159
-80 -65 -18 -295 -30 -295 -15 0 6 5 14 10 17 10 6 47 72 49 88 1 4 1 8 2 10
4 18 9 251 9 385 0 161 -2 176 -25 228 -26 59 -62 104 -82 104 -7 0 -13 4 -13
10 0 5 -19 15 -42 21 -24 7 -47 16 -52 21 -13 11 -233 10 -240 -1 -3 -5 -80
-9 -170 -10 -125 0 -173 -4 -201 -15 -21 -9 -41 -16 -46 -16 -5 0 -9 -4 -9 -9
0 -5 -9 -13 -20 -16 -11 -3 -20 -13 -20 -20 0 -8 -10 -21 -22 -29 -12 -9 -19
-16 -14 -16 5 0 3 -7 -4 -15 -7 -8 -10 -15 -7 -15 3 0 1 -15 -4 -32 -5 -19 -6
-111 -1 -223 l7 -190 5 118 c4 89 9 120 20 127 15 9 50 -14 50 -33 0 -6 -7 -2
-15 9 -8 10 -15 16 -15 12 0 -4 18 -27 39 -52 26 -30 39 -40 39 -28 -3 56 16
191 31 217 9 16 37 43 62 60 l44 30 195 3 c129 3 208 0 234 -8 46 -14 101 -68
115 -113 5 -18 12 -113 16 -212 7 -195 -1 -243 -48 -293 -42 -45 -75 -56 -187
-57 -223 -3 -250 -5 -250 -16 0 -5 5 -7 10 -4 6 3 10 1 10 -4 0 -6 5 -11 11
-11 5 0 8 4 5 9 -3 4 4 8 15 7 11 0 24 -8 29 -16 9 -13 10 -13 10 0 0 13 1 13
10 0 9 -13 10 -13 10 0 0 13 1 13 10 0 9 -13 10 -13 10 0 0 9 3 11 8 5 4 -6
15 -11 25 -10 9 0 17 -3 17 -7 0 -5 0 -13 0 -19 0 -8 -5 -8 -15 1 -12 10 -15
10 -15 -2 0 -13 -31 -21 -180 -43 -29 -4 -171 -39 -217 -54 -41 -12 -131 -60
-160 -85 -13 -11 -23 -30 -23 -43 0 -13 -4 -23 -10 -23 -5 0 -10 5 -10 11 0
22 -26 -10 -54 -66 -38 -78 -47 -195 -18 -250 27 -53 58 -81 110 -96 42 -12
44 -12 68 15 l25 27 -20 43 c-23 47 -26 66 -11 66 6 0 10 -6 10 -14 0 -8 13
-28 30 -44 24 -24 27 -32 16 -41 -32 -26 -37 -93 -34 -391 3 -320 3 -316 64
-389 29 -35 111 -81 143 -81 31 0 15 -27 -24 -41 -32 -12 -33 -14 -40 -82 -3
-38 -3 -73 2 -78 4 -4 87 17 183 48 96 30 198 63 227 71 45 15 52 21 58 48 l5
32 98 4 c53 2 108 10 121 17 28 16 109 23 117 10 2 -5 -7 -11 -21 -15 -15 -4
-28 -15 -31 -30 -11 -41 1 -54 50 -54 56 0 68 -16 18 -24 -79 -14 -133 -37
-128 -54 3 -10 8 -34 12 -54 l6 -38 67 0 c95 -1 243 -43 331 -94 60 -35 68
-40 110 -70 l41 -29 37 35 c20 19 60 50 87 69 63 43 279 154 348 179 331 116
459 150 802 211 66 11 131 23 145 26 14 2 91 12 173 21 131 14 147 18 147 35
0 16 -6 17 -42 13 -24 -3 -70 -8 -103 -11 -116 -9 -326 -40 -510 -76 -443 -87
-830 -241 -1049 -417 l-38 -32 -32 23 c-118 84 -287 149 -414 160 -60 5 -80
18 -59 35 8 7 61 24 118 38 102 25 105 25 159 9 83 -26 200 -89 295 -159 9 -7
31 6 71 41 187 162 553 326 784 352 52 6 109 13 125 15 51 7 184 22 280 31 33
3 76 8 95 10 34 3 265 18 305 19 13 1 20 8 20 21 0 18 -6 20 -60 18 -33 -2
-82 -5 -110 -8 -104 -12 -386 -28 -325 -18 14 2 32 7 40 10 31 12 236 46 353
58 66 6 67 7 67 35 0 18 7 33 18 38 14 8 144 20 409 36 34 3 71 10 84 17 28
15 119 99 119 110 0 4 5 20 10 34 7 19 14 24 23 18 18 -13 47 -10 57 7 7 10
10 11 10 3 0 -7 5 -13 10 -13 14 0 13 47 -2 56 -6 4 -17 -1 -23 -10 -12 -15
-13 -15 -20 1 -6 17 -7 17 -20 0 -18 -25 -24 -4 -13 53 9 45 -1 135 -24 229
-6 24 -28 64 -48 90 -56 72 -57 68 22 70 40 1 75 5 79 9 8 9 31 361 23 373 -3
5 -27 9 -54 9 l-49 0 -3 38 c-3 31 -7 37 -29 40 l-26 3 18 52 c15 47 16 82 10
347 l-7 295 -29 45 c-15 25 -35 51 -44 58 -39 31 -100 56 -146 58 -165 10
-246 11 -251 3z m161 -189 l0 -55 -42 -3 c-52 -4 -61 -15 -65 -80 l-4 -53 55
3 56 3 -3 -60 c-2 -33 -6 -62 -8 -64 -4 -4 -18 -6 -81 -10 -9 -1 -13 -45 -15
-168 l-3 -168 -65 0 -65 0 -3 167 -2 167 -53 3 -52 3 -3 63 -3 62 49 0 50 0 4
73 c6 129 57 177 183 174 l70 -2 0 -55z m-1306 -104 c59 -21 71 -59 71 -216 0
-74 -4 -140 -8 -146 -4 -6 -26 -21 -49 -33 -39 -20 -59 -21 -260 -23 -138 -1
-228 3 -244 9 -46 20 -59 66 -59 212 0 130 0 131 29 163 26 29 37 34 93 39
118 11 391 8 427 -5z m-1994 -136 c-3 -5 -2 -10 3 -10 6 0 13 -8 16 -17 6 -15
5 -15 -13 1 -18 16 -20 36 -5 36 3 0 2 -4 -1 -10z m2215 -385 c0 -8 -4 -15
-10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m-280 -17 c0 -32
34 -85 59 -93 28 -9 26 -19 -6 -45 -37 -29 -89 -21 -120 17 -30 37 -29 74 3
112 31 37 64 41 64 9z m1505 -39 c-18 -5 -33 -5 -39 1 -7 7 4 10 30 9 39 -1
39 -1 9 -10z m-2935 -53 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16
-4z m2607 -73 c-3 -10 -13 -37 -22 -61 -8 -24 -16 -71 -16 -105 -2 -187 -4
-175 39 -238 l41 -59 -32 3 -32 2 2 -55 c3 -90 -7 -160 -23 -160 -7 0 -16 -12
-20 -27 -12 -58 -13 -58 -41 -31 l-26 26 2 163 c1 89 6 170 12 180 14 27 10
218 -5 262 -8 20 -25 52 -40 71 -14 19 -26 37 -26 40 0 3 43 6 96 6 87 0 96
-2 91 -17z m580 7 c13 0 14 -18 8 -137 -4 -81 -12 -143 -19 -150 -12 -13 -156
-20 -175 -9 -6 4 -11 23 -11 43 0 29 3 34 18 28 48 -20 104 -37 107 -33 3 2 7
58 11 124 7 138 9 148 30 140 9 -3 22 -6 31 -6z m-289 -72 c-3 -7 -13 -15 -24
-17 -16 -3 -17 -1 -5 13 16 19 34 21 29 4z m-3416 -36 c-47 -43 -87 -124 -97
-194 -4 -27 -11 -48 -15 -48 -4 0 -13 -18 -20 -39 -12 -37 -10 -73 6 -128 15
-52 -65 -4 -96 57 -37 72 -20 155 49 250 44 61 97 102 160 125 56 21 57 18 13
-23z m2691 -33 c43 -33 47 -72 37 -329 -5 -129 -10 -277 -10 -327 l0 -92 28 4
c109 17 110 17 128 -15 11 -17 30 -32 46 -35 32 -8 46 -24 29 -35 -15 -9 -268
-33 -279 -26 -4 2 2 151 14 331 12 182 18 340 14 358 -13 55 -29 86 -50 99
-13 8 -20 23 -20 44 0 50 20 57 63 23z m759 -6 c11 -10 18 -30 18 -54 0 -38
-1 -38 -25 -27 -33 15 -59 66 -44 84 15 18 29 18 51 -3z m-1517 -46 c13 -21
14 -56 5 -289 -13 -328 -5 -378 72 -443 18 -15 41 -41 50 -57 10 -16 29 -33
43 -38 19 -7 25 -17 25 -39 0 -29 -1 -30 -65 -36 -83 -9 -106 -3 -150 42 -28
29 -35 44 -35 75 0 23 -5 41 -13 44 -8 3 -12 16 -11 32 12 114 35 575 30 596
-3 14 -19 32 -36 41 -23 12 -30 22 -30 45 0 26 4 30 43 38 23 6 45 10 49 11 4
1 14 -10 23 -22z m705 -37 c0 -11 5 -39 12 -63 9 -34 7 -101 -6 -308 -9 -145
-19 -294 -23 -331 l-5 -67 -42 -6 c-22 -4 -48 -9 -57 -11 -14 -4 -16 4 -15 49
1 50 2 53 26 49 14 -2 37 1 53 7 25 10 27 14 27 68 -1 32 2 135 6 228 5 94 8
223 8 288 0 64 4 117 8 117 4 0 8 -9 8 -20z m-379 -26 c13 -16 15 -192 2 -263
l-6 -34 -21 41 c-21 41 -25 96 -13 162 2 14 5 44 6 68 1 45 10 52 32 26z
m-1909 -114 c-2 -63 -10 -289 -17 -502 -12 -341 -16 -392 -33 -430 -10 -24
-29 -52 -41 -62 -15 -14 -21 -31 -21 -63 0 -23 1 -43 3 -43 1 0 56 20 122 45
66 25 124 45 128 45 5 0 7 -10 5 -22 -3 -19 -26 -30 -182 -80 -99 -31 -183
-55 -188 -52 -19 12 -7 55 20 71 67 39 63 15 103 627 33 507 39 561 55 573 10
8 25 12 34 11 12 -3 14 -22 12 -118z m1183 97 c13 -21 14 -59 4 -328 -6 -167
-15 -308 -19 -313 -9 -9 -46 -2 -68 12 -10 7 -10 51 3 232 19 289 19 296 -5
320 -22 22 -27 69 -8 88 21 21 76 15 93 -11z m-1295 -76 l0 -69 -64 -4 c-35
-1 -62 0 -59 5 2 4 -1 7 -6 7 -6 0 -11 10 -11 23 0 37 79 101 133 106 4 1 7
-30 7 -68z m1610 -6 c0 -19 -4 -37 -10 -40 -6 -4 -10 12 -10 40 0 28 4 44 10
40 6 -3 10 -21 10 -40z m1427 -92 c89 -71 133 -116 133 -134 0 -20 -31 -6
-106 50 -103 75 -175 161 -174 208 1 14 7 10 28 -16 14 -18 68 -67 119 -108z
m-2147 92 l23 -24 -7 -228 c-4 -125 -9 -267 -13 -315 -5 -87 -6 -88 -32 -88
-57 0 -57 -5 -39 266 16 251 13 296 -18 308 -21 8 -19 82 4 95 29 17 57 12 82
-14z m310 -50 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0
10 -11 10 -25z m-760 -17 c1 -7 15 -22 33 -33 l32 -21 0 -279 0 -280 27 -55
c31 -62 72 -95 145 -114 67 -18 96 -15 281 33 101 27 173 40 189 36 16 -4 29
-1 37 9 6 8 60 25 120 39 96 22 112 24 141 11 30 -12 32 -16 26 -46 -7 -34
-29 -44 -106 -52 -42 -4 -161 -25 -177 -31 -7 -3 -26 -7 -43 -9 -45 -6 -276
-54 -391 -82 -156 -37 -209 -30 -278 39 -67 68 -70 94 -50 408 9 146 14 278
10 293 -4 15 -17 31 -31 36 -17 7 -25 17 -25 34 0 35 10 56 26 56 8 0 14 5 14
10 0 15 20 12 20 -2z m-500 -88 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11
5 20 10 20 6 0 10 -9 10 -20z m860 -65 c0 -25 -5 -45 -11 -45 -6 0 -8 16 -5
43 5 50 5 47 11 47 3 0 5 -20 5 -45z m2321 -42 c36 -21 37 -31 4 -39 -28 -7
-35 -3 -39 18 -1 7 -5 19 -9 26 -12 17 10 15 44 -5z m-3131 -64 c0 -12 -13
-51 -30 -89 -34 -78 -38 -130 -15 -208 18 -60 16 -192 -4 -215 -13 -16 -69
-28 -76 -17 -3 4 5 40 17 80 20 65 20 76 8 113 -8 24 -14 82 -14 132 0 97 10
126 67 197 31 39 47 41 47 7z m406 -53 c-4 -31 -12 -56 -18 -56 -7 0 -8 14 -4
38 3 20 6 50 6 67 0 21 3 26 11 18 8 -8 9 -30 5 -67z m794 39 c0 -14 -4 -25
-10 -25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m790 -5 c0
-11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10 -9 10 -20z
m1455 -32 c-3 -24 -8 -52 -10 -63 -9 -61 -62 -125 -130 -155 -23 -11 -78 -21
-130 -24 -49 -4 -141 -11 -203 -17 -63 -6 -116 -8 -119 -5 -3 2 -2 36 2 75 l7
69 86 6 c48 4 104 8 125 10 30 3 37 1 33 -10 -8 -21 51 -54 96 -54 34 0 37 2
40 32 3 29 9 34 52 50 50 18 121 77 132 110 11 37 27 18 19 -24z m-1855 -103
c0 -30 -4 -55 -10 -55 -11 0 -14 93 -3 103 12 12 13 8 13 -48z m1880 35 c0 -5
-5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2676 -36 c3
-8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m2406 -16 c0 -6 -7 -7
-16 -4 -9 3 -20 6 -25 6 -5 0 -9 4 -9 9 0 4 11 6 25 3 14 -2 25 -9 25 -14z
m-2400 -37 c0 -17 -4 -33 -10 -36 -6 -4 -10 8 -10 29 0 20 5 36 10 36 6 0 10
-13 10 -29z m2018 -23 c-3 -49 -5 -53 -28 -53 -20 0 -26 6 -30 30 -4 25 -10
31 -35 33 -16 2 -34 7 -39 12 -19 18 8 30 70 30 l65 0 -3 -52z m-2428 -168 c0
-27 -4 -50 -10 -50 -5 0 -10 23 -10 50 0 28 5 50 10 50 6 0 10 -22 10 -50z
m1200 31 c14 -26 13 -74 -2 -89 -7 -7 -25 -12 -40 -12 -27 0 -28 2 -28 50 0
43 3 52 23 60 32 13 35 12 47 -9z"/>
<path d="M2142 3670 c2 -45 6 -84 10 -86 4 -3 32 11 63 31 30 19 60 35 65 35
21 0 9 17 -22 33 -18 8 -52 28 -76 42 l-43 27 3 -82z"/>
<path d="M663 3911 c-35 -4 -61 -14 -75 -29 -22 -22 -23 -28 -23 -236 0 -225
1 -233 49 -258 21 -11 21 -11 2 10 -27 31 -28 82 -2 105 11 10 16 16 11 13 -6
-2 -17 -1 -25 5 -11 6 -12 9 -2 9 7 0 11 4 8 8 -3 5 1 9 9 9 8 0 12 -4 9 -9
-4 -5 3 -12 15 -15 12 -3 21 -10 21 -15 0 -5 -6 -6 -12 -2 -7 4 -5 -2 6 -14
10 -12 23 -19 27 -16 5 3 9 0 9 -6 0 -6 4 -9 9 -5 5 3 11 -4 13 -16 2 -13 11
-23 23 -25 11 -2 21 -8 23 -14 2 -5 9 -7 14 -4 6 4 8 2 4 -4 -3 -5 0 -13 6
-16 7 -3 -1 -3 -19 0 -17 3 -34 1 -37 -5 -9 -13 264 -14 300 -1 16 6 39 23 52
37 21 25 22 33 22 229 l0 204 -29 32 -29 33 -164 2 c-89 0 -187 -2 -215 -6z
m379 -66 c10 -22 9 -29 -9 -46 -16 -17 -25 -19 -41 -10 -29 15 -35 41 -16 62
24 27 52 24 66 -6z m-124 -35 c120 -73 136 -220 35 -308 -38 -33 -65 -42 -124
-42 -121 0 -200 92 -185 214 6 42 15 61 44 93 20 22 51 45 67 51 51 18 126 14
163 -8z m-238 -320 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0
11 -4 11 -10z"/>
<path d="M769 3745 c-28 -15 -59 -70 -59 -105 0 -123 197 -150 230 -32 13 48
13 48 -6 88 -28 59 -105 82 -165 49z"/>
<path d="M611 3301 c22 -14 69 -21 69 -10 0 5 -11 9 -24 9 -13 0 -33 3 -43 6
-15 5 -16 4 -2 -5z"/>
<path d="M4420 2435 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"/>
<path d="M3809 1425 c-3 -2 -117 -5 -254 -6 -220 -1 -255 -3 -293 -19 -51 -23
-98 -70 -124 -128 -18 -41 -20 -65 -18 -345 1 -268 3 -305 19 -341 22 -50 54
-84 111 -122 l44 -29 295 1 c194 0 310 5 337 12 66 19 119 64 149 127 l27 57
-4 301 c-3 338 -4 340 -79 415 -51 51 -187 101 -210 77z m-73 -156 c93 -42
163 -155 158 -254 -4 -69 -9 -87 -39 -139 -31 -53 -74 -92 -130 -117 -51 -24
-72 -24 -120 1 -21 11 -42 17 -46 12 -4 -4 -14 -34 -24 -67 -22 -76 -52 -135
-69 -135 -46 0 -43 87 13 322 5 21 7 66 4 100 -4 59 -3 65 26 96 34 37 69 41
96 12 14 -16 17 -30 11 -72 -3 -29 -13 -73 -22 -97 -14 -42 -14 -46 4 -70 41
-56 125 -32 163 46 53 110 26 233 -64 282 -37 20 -53 23 -105 19 -70 -6 -119
-32 -160 -86 -23 -30 -27 -45 -27 -96 0 -41 6 -71 19 -92 14 -24 17 -37 9 -59
-12 -38 -25 -41 -60 -11 -67 56 -83 199 -32 283 79 132 255 186 395 122z"/>
<path d="M1955 1403 c-61 -8 -96 -27 -146 -78 -64 -64 -63 -63 -67 -378 -3
-242 -1 -297 13 -343 20 -71 78 -134 148 -164 51 -22 57 -23 342 -17 197 3
301 9 325 18 49 17 86 46 113 88 46 71 49 101 41 408 -7 277 -8 287 -31 332
-34 64 -57 86 -119 115 l-55 26 -267 -1 c-147 -1 -280 -4 -297 -6z m429 -181
c11 -40 68 -92 100 -92 28 0 36 -16 36 -70 0 -48 -16 -53 -85 -28 -27 10 -51
18 -52 18 -2 0 -3 -70 -3 -155 0 -169 -7 -197 -64 -256 -60 -64 -198 -77 -272
-27 -85 57 -125 175 -86 259 36 79 106 136 174 141 33 3 33 3 36 -43 3 -45 2
-48 -34 -65 -103 -51 -103 -174 -1 -213 32 -12 40 -11 74 5 65 32 68 48 65
311 -2 128 -1 237 3 243 4 6 27 10 53 8 43 -3 47 -5 56 -36z"/>
<path d="M1069 1385 c-3 -2 -116 -6 -252 -8 -136 -2 -250 -7 -254 -12 -5 -4
-1 -5 7 -3 8 3 23 1 33 -4 12 -6 17 -5 17 4 0 9 3 9 10 -2 5 -9 5 -19 -1 -26
-7 -8 -6 -14 1 -19 6 -4 9 -2 8 3 -2 6 1 11 6 11 6 1 11 -6 12 -15 4 -25 20
-37 20 -15 0 11 -5 17 -11 14 -5 -4 -7 -2 -2 2 4 5 0 16 -8 25 -13 15 -13 17
0 17 8 0 15 -4 15 -9 0 -5 4 -7 9 -4 5 3 8 0 7 -7 0 -6 4 -12 9 -12 6 0 9 4 7
10 -2 5 2 4 9 -2 17 -17 21 -46 6 -36 -20 12 -38 12 -36 0 4 -31 -2 -58 -11
-52 -6 4 -8 10 -6 14 3 4 -2 8 -10 8 -11 0 -9 -6 7 -23 13 -14 19 -30 15 -39
-3 -9 -2 -13 4 -10 6 4 7 -1 3 -12 -6 -16 -5 -16 10 -4 12 10 14 17 7 26 -7 9
-5 16 7 26 16 12 17 12 6 -2 -7 -9 -10 -19 -6 -23 10 -10 12 -36 3 -33 -4 1
-7 -2 -7 -8 1 -5 5 -9 8 -8 3 1 6 -4 6 -11 0 -7 -6 -13 -13 -13 -7 0 -11 3 -9
7 2 4 -6 17 -19 29 -17 16 -23 18 -23 7 0 -8 4 -13 9 -10 4 3 8 -1 8 -8 0 -7
6 -25 14 -40 12 -23 12 -26 0 -21 -8 3 -14 13 -15 22 0 9 -3 11 -6 4 -3 -9
-13 -5 -31 12 -14 13 -30 21 -35 18 -5 -3 -13 1 -18 8 -7 12 -10 12 -21 0 -7
-8 -8 -11 -2 -8 6 3 28 -12 50 -34 47 -49 120 -88 184 -98 l48 -8 7 45 c10 67
57 113 121 119 38 4 60 0 90 -15 36 -19 41 -19 77 -5 43 18 57 11 35 -14 -28
-33 -29 -40 -3 -35 33 6 31 -8 -5 -38 -23 -19 -30 -33 -30 -60 -2 -194 -169
-372 -365 -387 -64 -6 -199 19 -211 38 -3 5 -11 9 -17 9 -7 -1 -1 -10 12 -20
13 -11 20 -20 16 -20 -5 0 5 -11 21 -25 16 -14 33 -25 37 -25 5 0 6 -4 2 -9
-3 -5 0 -13 6 -17 8 -4 9 -3 5 4 -4 7 -2 12 3 12 6 0 12 -4 12 -10 1 -5 2 -14
3 -19 0 -5 4 -13 8 -16 4 -4 3 -15 -2 -25 -6 -12 -5 -17 4 -17 7 0 12 10 11
21 -1 12 -3 21 -4 21 -2 0 -4 8 -6 19 -3 14 -1 16 8 7 6 -6 12 -23 13 -38 1
-16 4 -34 5 -42 2 -7 -3 -16 -11 -18 -13 -4 -13 -3 0 6 12 8 11 10 -7 9 -12
-1 -19 -7 -16 -14 3 -8 -8 -12 -35 -11 -22 1 -38 -1 -35 -6 2 -4 -1 -7 -6 -7
-6 0 -11 5 -11 11 0 5 -5 7 -10 4 -6 -4 -18 -1 -27 7 -9 7 -19 12 -22 10 -3
-2 -21 9 -39 23 -48 39 -75 98 -82 185 l-7 75 -1 -70 c-3 -116 46 -198 143
-245 37 -18 64 -20 320 -20 307 0 355 6 418 54 20 15 48 52 62 81 l26 53 -4
294 c-2 202 -7 305 -16 333 -19 56 -100 131 -164 150 -51 15 -90 19 -98 10z
m-378 -148 c-10 -9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m22 -705
c15 -16 15 -19 2 -19 -8 0 -13 3 -10 5 3 3 0 11 -6 19 -17 20 -5 16 14 -5z"/>
<path d="M435 1300 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M653 1215 c0 -8 4 -12 9 -9 5 3 6 10 3 15 -9 13 -12 11 -12 -6z"/>
<path d="M594 1199 c-4 -7 -3 -9 4 -5 5 3 13 0 15 -6 4 -9 6 -10 6 -1 1 17
-16 25 -25 12z"/>
<path d="M535 1130 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M527 1070 c3 -11 7 -20 9 -20 2 0 4 9 4 20 0 11 -4 20 -9 20 -5 0 -7
-9 -4 -20z"/>
<path d="M534 994 c0 -27 2 -41 4 -31 3 15 9 17 30 12 26 -6 26 -6 9 12 -10
10 -24 26 -31 37 -11 16 -13 13 -12 -30z"/>
<path d="M384 850 c0 -58 1 -81 3 -52 2 28 2 76 0 105 -2 28 -3 5 -3 -53z"/>
<path d="M564 875 c1 -7 8 -21 16 -31 8 -10 10 -15 3 -11 -7 4 -10 1 -9 -10 2
-9 8 -19 14 -21 7 -2 12 -10 12 -18 0 -8 3 -14 8 -14 4 0 8 -11 8 -25 1 -16
-3 -25 -13 -25 -9 0 -11 -5 -7 -12 6 -10 4 -10 -7 -2 -17 13 -28 12 -54 -3
-37 -23 96 -8 140 15 l40 20 -28 11 c-16 6 -32 11 -36 11 -4 0 -17 11 -29 25
-23 26 -29 55 -12 55 19 0 10 15 -19 31 -21 13 -28 14 -27 4z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
